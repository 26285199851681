import { Controller } from "@hotwired/stimulus";
import { router } from "@inertiajs/react";

export default class NavigationController extends Controller {
  static values = {
    url: String,
  };

  // 型定義を追加
  declare readonly urlValue: string;

  connect() {
    console.log(this.urlValue);
    console.log("connect");
  }

  navigate(): void {
    console.log("navigate");
    router.visit(this.urlValue);
  }
}
